import React, { useState } from 'react';
import { Button } from '@janus.team/janus-particles';
import { Mask } from './Mask';

export const KeyValueView = ({ detailsMap, resetAPIKey, isAdmin }) => {
  const [musked, setMusked] = useState(true);

  const viewAPIKey = () => {
    setMusked(!musked);
  };

  const detailsMapView = Object.keys(detailsMap)?.map((key) => {
    const detailsLabel = detailsMap[key]?.key;
    const detailsValue = detailsMap[key].value;
    const APIKey = 'API Key';
    return (
      <div key={detailsLabel}>
        <div className="detailsKey">
          {detailsLabel} &nbsp;
          {detailsLabel === APIKey && isAdmin && (
            <Button
              as="button"
              onClick={resetAPIKey}
              tertiary
            >
              Reset
            </Button>
          )}
        </div>
        <div className="detailsValue">
          {
            detailsMap[key].loading && (<div className="rsd-skeleton__placeholder rsd-skeleton">Loading...</div>)
          }
          {
            [APIKey, 'Docs Link'].indexOf(detailsLabel) < 0 && detailsValue && (<div>{detailsValue}</div>)
          }
          {
            detailsLabel === 'Docs Link' && (
              <a rel="noreferrer" href={detailsValue} target="_blank">{detailsValue}</a>
            )
          }
          {
            detailsLabel === APIKey && detailsValue && (
              <div>
                <Mask value={detailsValue} masked={musked} length={detailsValue?.length} />
                <Button
                  as="button"
                  onClick={viewAPIKey}
                  tertiary
                >
                  {musked ? 'View' : 'Hide' }
                </Button>
              </div>
            )
          }
        </div>
      </div>
    );
  });

  return (
    <div className="detailsGrid">
      { detailsMapView }
    </div>
  );
};
