import React, { useEffect, useState } from 'react';
import { context as janusContext } from '@janus.team/janus-copilot/cjs/janus-copilot.js';
import {
  useQueryListCustomerAccountsForDomain, useQueryGetAPICredentials, useQueryGetCustomerInformation
} from 'customer/queries.js';
import { Switch, Route, useParams } from 'react-router-dom';
import AccountList from 'customer/account-list.js';
import { Helmet } from 'react-helmet';
import { Page } from '@janus.team/janus-particles';
import { t } from 'translations/index.js';
import { isProd, hasRackerRole, hasAccess, hasAdminAccess } from '../config/index';
import { BreadCrumb } from 'common/BreadCrumb';
import { KeyValueView } from 'common/KeyValueView';
import { accountTypeDescriptorForAccount, resetAPICredentials } from './data';
import { Error403 } from 'common/Error';

export const context = React.createContext({});

export const isOptionDisabled = (loginType, accountType, roles) => {
  if (loginType === 'racker') {
    if (accountType.name === 'AZURE') {
      return !roles?.some((role) => {
        return role.name === 'rax-aws-saml-janus-prod-admins'
          || role.name === 'janus-azure-product-architecture'
          || role.name === 'janus-azure-service-admins'
          || role.name === 'rss-platform-eng';
      });
    } else if (accountType.name === 'GCP') {
      return !roles?.some((role) => {
        return role.name === 'mgcp-support'
          || role.name === 'MPC-TOMS'
          || role.name === 'rax-aws-saml-janus-prod-admins';
      });
    }
    // any racker can access VMWARE
    return false;
  }

  if (accountType.name === 'AWS') {
    return !roles?.some((role) => {
      return role.name === 'identity:user-admin'
        || role.name === 'aws:account-creator';
    });
  } else if (accountType.name === 'AZURE') {
    return !roles?.some((role) => {
      return role.name === 'identity:user-admin'
        || role.name === 'azure:account-creator'
        || role.name === 'identity:user-manage';
    });
  }
  // for customer GCP
  return false;
};

export const getURL = (accountType, domainId) => {
  if (isProd()) {
    return accountType.link.replace('DOMAIN_ID', domainId);
  }
  return accountType.link.replace('DOMAIN_ID', domainId)
    .replace('manage.rackspace.com', 'dev.manage.rackspace.com');
};

export const RackerAccountView = () => {
  const domain = useParams()?.domain;
  const customerInformationQuery = useQueryGetCustomerInformation(domain);
  const className = customerInformationQuery?.query?.isLoading ? 'rsd-skeleton__placeholder rsd-skeleton' : '';
  const name = customerInformationQuery?.query?.data?.name || t('Customer');
  const category = <span>{domain}</span>;
  const title = <span {...{ className }}>{name}</span>;
  useEffect(()=>{
    if(window.pilot){
      // Updating the co-pilot context for navigation
      // though react-router
      window.pilot.setCustomer(name, domain);
    }
  },[name, domain]);

  return (
    <context.Provider value={customerInformationQuery?.query?.data}>
      <Helmet>
        <title>{name} | {domain} | {t('Account List')} | {t('Rackspace')}</title>
      </Helmet>
      <Page.Main>
        <Page.MainHeader {...{ title, category }} withBackground />
        <Page.MainBody>
          <Switch>
            <Route path="/racker/customers/:domain">
              <AccountList />
            </Route>
          </Switch>
        </Page.MainBody>
      </Page.Main>
    </context.Provider>
  );
};

export const CustomerAccountView = () => {
  const title = t('Accounts');
  return (
    <>
      <Helmet>
        <title>{title} | {t('Rackspace')}</title>
      </Helmet>
      <Page.Main>
        <Page.MainHeader title={title} withSections/>
        <Page.MainBody>
          <AccountList />
        </Page.MainBody>
      </Page.Main>
    </>
  );
};

export const DetailsView = () => {
  const domain = useParams()?.domain;
  const account = useParams()?.account;
  
  const { session } = React.useContext(janusContext.session);
  const [title, setTitle] = useState();
  const isRacker = hasRackerRole(session?.user?.roles);

  const { query: getAPICredentislsQuery } = useQueryGetAPICredentials(session.user.id, isRacker);
  const { query: getCustomerAccountList } = useQueryListCustomerAccountsForDomain(domain);
  
  const [detailList, setDetailList] = useState({
    'id': { key: 'ID', value: null, loading: true },
    'created_on': { key: 'Created On', value: null, loading: true },
    ...(!isRacker && {'api_key': { key: 'API Key', value: null, loading: true }}),
    'type': { key: 'Type', value: null, loading: true },
    'docs': { key: 'Docs Link', value: null, loading: true },
    'domainName' : { key: 'Domain Name', value: null, loading: true }
  });

  useEffect(() => {
    if (account && getAPICredentislsQuery?.data && getCustomerAccountList?.data) {
      const customerAccountDetails = getCustomerAccountList.data.find((acc) => acc?.id === account);
      const createdDate = new Date(customerAccountDetails?.createdDate);
      const options = {
        hour: 'numeric', minute: 'numeric', year: 'numeric', month: 'numeric', day: 'numeric', hour12: true,
        timeZone: 'UTC', timeZoneName: 'short'
      };
      const dateString = `${createdDate.toLocaleString('en-US', options)}`;
      setDetailList({
        'id': { key: 'ID', value: customerAccountDetails?.id, loading: false },
        'created_on': { key: 'Created On', value: dateString, loading: false },
        'domainName': { key: 'Domain Name', value: 'rackspace_cloud_domain', loading: false },
        ...(!isRacker && {'api_key': { key: 'API Key', value: getAPICredentislsQuery.data['RAX-KSKEY:apiKeyCredentials'].apiKey, loading: false }}),
        'type': { key: 'Type', value: accountTypeDescriptorForAccount(customerAccountDetails), loading: false },
        'docs': { key: 'Docs Link', value: 'https://docs.rackspacecloud.com/openstack-getting-started-cli', loading: false }
        
      });
      setTitle(<div>
        <BreadCrumb isRacker={isRacker} accountName={customerAccountDetails.name} />
        <div>{customerAccountDetails.name}</div>
      </div>);
    }
    // eslint-disable-next-line
  }, [account, getAPICredentislsQuery, getCustomerAccountList]);

  const resetAPIKey = async () => {
    setDetailList({
      ...detailList,
      'api_key': { key: 'API Key', value: null, loading: true }
    });
    const response = await resetAPICredentials(session.user.id);
    setDetailList({
      ...detailList,
      'api_key': { key: 'API Key', value: response['RAX-KSKEY:apiKeyCredentials'].apiKey, loading: false }
    });
  };
  if (!hasAccess(session.user.roles, account)) {
    return (
      <>
        <Error403 />
      </>
    );
  }
  return (
    <>
      <Page.Main>
        <Page.MainHeader
          {...{ title: title? title : <span className="rsd-skeleton__placeholder rsd-skeleton">loading</span> }}
          withBackground
        />
        <Page.MainBody>
          <Page.MainBodySection>
            <KeyValueView
              detailsMap={detailList}
              resetAPIKey={resetAPIKey}
              isAdmin={hasAdminAccess(session.user.roles, account)}
            />
          </Page.MainBodySection>
        </Page.MainBody>
      </Page.Main>
    </>
  );
};
