import _ from 'lodash';
import { proxyFetch } from 'data/proxy.js';

const IDENTITY_BASE_URL = 'https://identity.api.rackspacecloud.com/v2.0';
/**
 * Provides the API credetials of user.
 *
 * @example
 * https://docs.rackspace.com/docs/tenants#list-tenants
 * @returns {Promise}
 */
export const getTenantList = () => {
  const url = `${IDENTITY_BASE_URL}/tenants`;
  return proxyFetch(url, { headers: { Accept: 'application/json' } })
    .then(_.method('parseJson'));
};

/**
 * Provides the API credetials of user.
 *
 * @example
 * https://docs.rackspace.com/docs/cloud-identity-v2-api-reference-users-operations#get-user-credentials
 *
 * @param {String} userId
 * @returns {Promise}
 */
export const getAPICredentials = (index, userId, isRacker) => {
  if (!isRacker) {
    const url = `${IDENTITY_BASE_URL}/users/${userId}/OS-KSADM/credentials/RAX-KSKEY:apiKeyCredentials`;
    return proxyFetch(url, { headers: { Accept: 'application/json' } })
      .then(_.method('parseJson'));
  }
  return true;
};

/**
 * Provides the API credetials of user.
 *
 * @example
 * https://docs.rackspace.com/docs/cloud-identity-v2-api-reference-users-operations#reset-api-key-for-user
 *
 * @param {String} userId
 * @returns {Promise}
 */
export const resetAPICredentials = (userId) => {
  // TODO: rectify url
  const url = `${IDENTITY_BASE_URL}/users/${userId}/OS-KSADM/credentials/RAX-KSKEY:apiKeyCredentials/RAX-AUTH/reset`;
  return proxyFetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json'
    }
  })
    .then(_.method('parseJson'));
};
