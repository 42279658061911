import _ from 'lodash';
import { baseUrl } from 'config/index.js';
import { t } from 'translations/index.js';

export { getGCPAccessGrants, setGCPAccessGrants } from 'customer/api/janus';
export { searchCustomers } from 'customer/api/encore.js';
export { listCustomerAccountsForDomain, getCustomerInformation, getCustomerAccountDetails, getCustomerAccountMetadata } from 'customer/api/cms.js';
export { getTenantList, getAPICredentials, resetAPICredentials } from 'customer/api/identity';

export const privateCloudTenants = ['RPC_V', 'RSVC', 'RSDP'];

export const filterOspcFromCustomerAccounts = (accounts) => {
  if (accounts?.some((account) => privateCloudTenants.includes(account?.type))){
    return accounts?.filter((account) => !account?.type?.includes('CLOUD'));
  }
  return accounts;
};

export const accountTypeDescriptorForAccount = (account) => {
  const ACCOUNT_TYPE_TO_DESCRIPTOR_MAP = {
    AZURE: t('Azure'),
    CLOUD: t('OpenStack Public Cloud'),
    FANATICAL_SUPPORT_FOR_AWS: t('AWS'),
    MANAGED_GCP: t('GCP'),
    MANAGED_HOSTING: t('Managed Hosting'),
    MANAGED_VMC: t('VMware Cloud on AWS'),
    RPC_V: t('SDDC Business / Enterprise'),
    MPK: t('Managed Platform for Kubernetes'),
    RSVC: t('SDDC Flex'),
    ROS: t('Object Storage'),
    RSDP: t('Data Protection'),
    OPENSTACK_FLEX: 'OpenStack Flex'
  };
  return ACCOUNT_TYPE_TO_DESCRIPTOR_MAP[account?.type] || account?.type;
};

export const hasConsoleLink = (account) => {
  return account?.status === 'Active' && consoleUrlForAccount(account);
};

export const hasDetailsLink = (account) => {
  return account?.status === 'Active' && detailsUrlForAccount(account);
};

export const consoleUrlForAccount = (account, isRacker = true) => {
  const ACCOUNT_TYPE_CONSOLE_URL_FACTORY_RACKER = {
    MANAGED_VMC: (a) => `https://vmc.vmware.com/home?orgLink=/csp/gateway/am/api/orgs/${a.id}`,
    FANATICAL_SUPPORT_FOR_AWS: (a) => `${baseUrl()}/racker/rackspaceAccounts/${a.domain}/awsAccounts/${a.id}/consoleLogoutRedirect`,
    MANAGED_GCP: (a) => `https://console.cloud.google.com/home/dashboard?project=${a.accessGrants[0]?.projectId}&authuser=${a.accessGrants[0]?.gcpEmail}`,
    AZURE: (a) => `${baseUrl()}/azure/racker/rackspace-accounts/${a.domain}/subscriptions/${a.id}/portal`,
    MPK: (a) => `https://rs${a.domain}.console.rackspace-mpk.com`
  };

  const ACCOUNT_TYPE_CONSOLE_URL_FACTORY_CUSTOMER = {
    MANAGED_VMC: (a) => `https://vmc.vmware.com/home?orgLink=/csp/gateway/am/api/orgs/${a.id}`,
    FANATICAL_SUPPORT_FOR_AWS: (a) => `${baseUrl()}/aws/account/${a.id}/consoleSignin`,
    MANAGED_GCP: (a) => `https://console.cloud.google.com/home/dashboard?project=${a.id}`,
    AZURE: (a) => `${baseUrl()}/azure/subscriptions/${a.id}/portal`,
    MPK: (a) => `https://rs${a.domain}.console.rackspace-mpk.com`
  };

  const factory = isRacker ? ACCOUNT_TYPE_CONSOLE_URL_FACTORY_RACKER[account?.type] : ACCOUNT_TYPE_CONSOLE_URL_FACTORY_CUSTOMER[account?.type];
  return factory ? factory(account) : null;
};

export const detailsUrlForAccount = (account, isRacker = true) => {
  const isAzureV1 = _.includes(account.domain, 'dedicated:');
  const coreAccountNumber = _.replace(account.domain, 'dedicated:', '');

  const ACCOUNT_TYPE_DETAILS_URL_FACTORY_RACKER = {
    AZURE: (a) => isAzureV1 ?
      `https://ops.mscloud.rackspace.com/accounts/${coreAccountNumber}/subscriptions/${account.id}` :
      `${baseUrl()}/azure/racker/rackspace-accounts/${a.domain}/subscriptions/${a.id}`,
    CLOUD: (a) => `https://encore.rackspace.com/accounts/${a.domain}`,
    FANATICAL_SUPPORT_FOR_AWS: (a) => `${baseUrl()}/racker/rackspace-accounts/${a.domain}/aws-accounts/${a.id}`,
    MANAGED_GCP: (a) => `${baseUrl()}/gcp/racker/customers/${a.domain}/projects/${a.id}`,
    MANAGED_HOSTING: (a) => `https://core.rackspace.com/py/core/#/account/${a.id}`,
    MANAGED_VMC: (a) => `${baseUrl()}/vmware/racker/${a.domain}/vmc/accounts/${a.id}`,
    RPC_V: (a) => `${baseUrl()}/vmware/racker/${a.domain}/rpcv/organization/${a.id}`,
    RSVC: (a) => `${baseUrl()}/vmware/racker/${a.domain}/rsvc/accounts/${a.id}`,
    OPENSTACK_FLEX: (a) => `${baseUrl()}/dashboard/racker/details/${a.domain}/${a.id}`
  };

  const ACCOUNT_TYPE_DETAILS_URL_FACTORY_CUSTOMER = {
    AZURE: (a) => isAzureV1 ?
      'https://auth-dev.mscloud.rackspace.com/'
      : `${baseUrl()}/azure/subscriptions`,
    CLOUD: (a) => `https://mycloud.rackspace.com/cloud/${a.domain}/home`,
    FANATICAL_SUPPORT_FOR_AWS: (a) => `${baseUrl()}/aws/accounts/${a.id}`,
    MANAGED_GCP: (a) => `${baseUrl()}/gcp/projects`,
    MANAGED_HOSTING: (a) => `https://my.rackspace.com/portal/rs/${a.id}/home`,
    MANAGED_VMC: (a) => `${baseUrl()}/vmware/${a.domain}/vmc/accounts/${a.id}`,
    RPC_V: (a) => `${baseUrl()}/vmware/${a.domain}/rpcv/organization/${a.id}`,
    RSVC: (a) => `${baseUrl()}/vmware/${a.domain}/rsvc/accounts/${a.id}`,
    OPENSTACK_FLEX: (a) => `${baseUrl()}/dashboard/details/${a.domain}/${a.id}`
  };

  const factory = isRacker ? ACCOUNT_TYPE_DETAILS_URL_FACTORY_RACKER[account?.type] : ACCOUNT_TYPE_DETAILS_URL_FACTORY_CUSTOMER[account?.type];
  return factory ? factory(account) : null;
};

export const accountStatusClass = (account = {}) => _.get({
  closed: 'light',
  fraud: 'danger',
  active: 'success',
  suspended: 'warning'
}, _.kebabCase(account?.mappedStatus ?? account), 'danger');

const serviceLevelDescriptorsForAccount = (account) => {
  const SERVICE_LEVEL_TO_DESCRIPTOR_MAP = {
    'aviator': t('Aviator'),
    'navigator': t('Navigator'),
    'infrastructure_sysops': t('Managed Infrastructure'),
    'intensive': t('Intensive'),
    'managed_sysops': t('Managed Operations'),
    'managed': t('Managed'),
    'serviceblocks': t('Service Blocks')
  };

  const descriptor = SERVICE_LEVEL_TO_DESCRIPTOR_MAP[account?.serviceLevel] || account?.serviceLevel;
  return [descriptor];
};

const serviceBlockDescriptorsForAccount = (account) => {
  const METADATA_TO_DESCRIPTOR_MAP = {
    'advanced_monitoring_resolution:status': t('Advanced Monitoring & Resolution'),
    'architect_and_deploy:status': t('Architect & Deploy'),
    'architect_and_deploy_1x:status': t('Architect & Deploy - One-Time'),
    'dba_services:status': t('DBA Services'),
    'discover_and_enhance_1x:status': t('Discover & Enhance'),
    'elastic_engineering_data_ops:status': t('Elastic Engineering DataOps'),
    'rackspace_data_ops_dpm:status': t('Rackspace DataOps DPM'),
    'elastic_engineering:status': t('Elastic Engineering'),
    'cloud_dbops:status': t('Cloud DBops Services'),
    'vmm:status': t('VM Management'),
    'proserv:status': t('Professional Services'),
    'modops:status': t('Modern Operations'),
    'global_incident_response:status': t('Global Incident Response'),
    'manage_and_operate:status': t('Manage & Operate'),
    'optimizer:status': t('Optimizer'),
    'optimizer_plus:status': t('Optimizer+'),
    'optimizerpluswithplatformsupport:status': t('Optimizer+ with Platform Support'),
    'platform_essentials:status': t('Platform Essentials'),
    'rackspace_teams:status': t('Rackspace Teams'),
    'rms:status': t('Rackspace Managed Security'),
    'rms:al_cloud_defender:status': t('AlertLogic Cloud Defender'),
    'rms:alss_essentials:status': t('AlertLogic Security Solutions - Essentials'),
    'rms:alss_professional:status': t('AlertLogic Security Solutions - Professional'),
    'rms:armor_anywhere:status': t('Armor Anywhere'),
    'rms:cns:status': t('Cloud Native Security'),
    'rms:compliance_assistance:status': t('Compliance Assistance'),
    'rms:network_protection:status': t('Network Protection'),
    'rms:pdp:status': t('Privacy & Data Protection'),
    'rms:pdr:status': t('Proactive Detection & Response'),
    'rms:pdrv2_base:status': t('Proactive Detection & Response'),
    'rsvc_platform_support:status': t('Platform Support')
  };

  const activeAddOnKeys = Object.keys(account?.metadata || {}).filter((key) => {
    return key.endsWith(':status') && _.get(account?.metadata, key) === 'Active';
  });
  const serviceBlockNames = activeAddOnKeys.filter((key) => key in METADATA_TO_DESCRIPTOR_MAP).map((key) => {
    return METADATA_TO_DESCRIPTOR_MAP[key];
  });
  return _.uniq(serviceBlockNames.sort());
};

export const serviceDescriptorsForAccount = (account) => {
  if (!account?.isServiceLevel) {
    return serviceBlockDescriptorsForAccount(account);
  }
  return serviceLevelDescriptorsForAccount(account);
};

const FRIENDLY_STATUS_MAP = {
  'Delinquent': t('Active'),
  'Pending Migration': t('Other'),
  'TestStatus': t('Other')
};

export const getFriendlyStatus = (accountStatus) => {
  return FRIENDLY_STATUS_MAP[accountStatus] || accountStatus;
};

export const mapAccountStatus = (accounts = []) => {
  const mappedStatusList = accounts.map((account) => {
    return { ...account, mappedStatus: getFriendlyStatus(account.status)};
  });
  return mappedStatusList;
};
