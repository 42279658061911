import { t } from 'translations/index.js';
import { UserWarningSvg } from '../assets/UserWarningSvg';


export function ErrorSection({
  image,
  heading,
  subHeading,
  message
}) {
  return (
    <section className="text-center status-code-errors" data-testid="status-code-errors">
      {image}
      <div>{heading}</div>
      <div> {subHeading}</div>
      {message !== '' && <div>{message}</div>}
    </section>
  );
}


function Error403({ heading, subHeading, message, className, img }) {
  return (
    <ErrorSection
      image={
        img ?? (
          <div className="no-permissions-img">
            <UserWarningSvg size="7.5em" />
          </div>
        )
      }
      heading={heading ?? t('insufficientPermission')}
      subHeading={subHeading ?? t('noResourcesPermission')}
      message={message ?? t('contactAccountAdmin')}
      className={className}
    />
  );
}

export { Error403 };
