export function UserWarningSvg(props) {
  return (
    <svg
      width={props.size || '3em'}
      height={props.size || '3em'}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Insufficient Permissions</title>
      <path
        d="M39.156 40.629c-.516-6.476-5.097-11.81-11.189-13.46 2.856-1.453 4.821-4.414 4.821-7.831 0-4.846-3.942-8.788-8.788-8.788s-8.788 3.942-8.788 8.788c0 3.417 1.965 6.378 4.82 7.831-6.092 1.65-10.673 6.984-11.188 13.46C4.332 36.514 1.5 30.588 1.5 24 1.5 11.574 11.573 1.5 24 1.5c4.715 0 9.09 1.454 12.706 3.933l-3.544 6.029-.05.096c-.332.712.172 1.559.969 1.559h9.612A22.39 22.39 0 0 1 46.5 24c0 6.588-2.832 12.514-7.344 16.629M16.712 19.338c0-4.018 3.269-7.288 7.288-7.288 4.018 0 7.288 3.27 7.288 7.288 0 4.019-3.27 7.288-7.288 7.288-4.019 0-7.288-3.269-7.288-7.288M24 46.5c-5.165 0-9.92-1.744-13.717-4.67C10.29 34.272 16.44 28.126 24 28.126c7.559 0 13.709 6.146 13.717 13.704A22.395 22.395 0 0 1 24 46.5M40.036 9.495a.939.939 0 0 1 1.405.812.937.937 0 1 1-1.405-.812m.017-5.622a.848.848 0 0 1 1.293.808l-.328 3.286a.518.518 0 0 1-1.031 0l-.328-3.286a.849.849 0 0 1 .394-.808m5.326 9.244h1.548l.107-.005c.769-.075 1.222-.954.811-1.65L41.422.532l-.068-.105a1.059 1.059 0 0 0-1.768.105l-2.123 3.612A23.854 23.854 0 0 0 24 0C10.766 0 0 10.767 0 24c0 13.234 10.766 24 24 24 13.233 0 24-10.766 24-24 0-3.916-.949-7.613-2.621-10.883"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
