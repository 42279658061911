import _ from 'lodash';
import React from 'react';
import { serviceDescriptorsForAccount } from 'customer/data.js';
import { useQueryGetCustomerAccountMetadata } from 'customer/queries.js';
import * as b from 'react-bootstrap';

export const ServiceBlocks = ({ account }) => {
  const { query: detailsQuery } = useQueryGetCustomerAccountMetadata(account);
  const serviceDescriptors = serviceDescriptorsForAccount(_.merge({}, account, detailsQuery?.data));

  if (!serviceDescriptors?.length && !detailsQuery.isLoading) {
    return (
      <span className="rsd-card__service-block-list">N/A</span>
    );
  }
  if (!serviceDescriptors?.length && detailsQuery.isLoading) {
    return (
      <span className="rsd-card__service-block-list">
        <div className="rsd-card-inline-loading-indicator">
          <b.Spinner animation="border" variant="dark" size="sm" />
        </div>
      </span>
    );
  }
  return (
    <div className="d-flex flex-row flex-wrap gap-2">
      {_.map(serviceDescriptors, (serviceBlock) => {
        if (!serviceBlock) {
          return (
            <span className="rsd-card__service-block-list">N/A</span>
          );
        }
        return (
          <b.Badge
            bg="light"
            text="light"
            variant="light" key={serviceBlock}
          >
            {serviceBlock}
          </b.Badge>
        );
      })}
    </div>
  );
};
